import { Button, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomModal } from "../sharedComponents";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import useUpdateOrder from "../../customHooks/order/useUpdateOrder";

const InterestChargeForm = ({ orderID, orderInterest, orderTotal }) => {
  const [interest, setInterest] = useState(orderInterest?.percent ?? 0);
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState(true);

  const updateOrder = useUpdateOrder();

  useEffect(() => {
    if (!interest) {
      return setValid(false);
    }

    if (interest < 0) {
      return setValid(false);
    }

    setValid(true);
  }, [interest]);

  const onClickSetInterest = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onSubmit = async () => {
    setSaving(true);

    await updateOrder.mutateAsync({
      id: orderID,
      order: {
        interest: {
          amount: orderTotal * (interest / 100),
          percent: interest,
        },
      },
    });

    closeModal();
    setSaving(false);
  };

  return (
    <>
      <CustomModal open={modalOpen} close={closeModal} title="Interest">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <DecimalTextField
              editMode={true}
              value={interest}
              onChange={(e) => setInterest(e.target.value)}
              label="Interest"
              required={true}
              allowMinus={false}
              allowDecimal={true}
              inputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={onSubmit}
              fullWidth
              disabled={!valid || saving}
            >
              {saving ? "Saving" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickSetInterest}
        >
          Set Interest
        </Button>
      </Grid>
    </>
  );
};

export default InterestChargeForm;
