import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addToProductsInCartByWarehouse,
  selectProductsInCartByWarehouse,
  setOrderFacility,
  selectOrderFacility,
  setOrderFacilityAddress,
} from "../../../redux/reducers/productsSlice";
import { checkFacilityUserLevelLogger } from "../../../sharedFunctions/userLevels";
import useGetClosestWarehouse from "../../../customHooks/products/useGetClosestWarehouse";
import useExchangeRate from "../../../customHooks/exchangeRates/useExchangeRate";
import calculateProductPrice from "../../../sharedFunctions/purchasingFunctions/calculateProductPrice";
import { getProductShippingPrice } from "../../../sharedFunctions/purchasingFunctions/getProductShippingPrice";
import { getProductAndShippingTotal } from "../../../sharedFunctions/purchasingFunctions/getProductAndShippingTotal";
import useShippingRate from "../../../customHooks/shippingRate/useShippingRate";

const ProductCard = ({
  close,
  defaultAmount,
  facility,
  facilityUserLevel,
  originalProductID, //the original product if a equivalent product is selected
  supplierProductDetail,
  color,
  size,
  onClickShowEquivalentProducts,
}) => {
  const dispatch = useDispatch();
  const amountToUse = defaultAmount ?? 1;
  const productsInCartByWarehouse = useSelector(
    selectProductsInCartByWarehouse
  );
  const orderFacility = useSelector(selectOrderFacility);

  const { exchangeRate, isInitialLoading } = useExchangeRate({
    currency: supplierProductDetail.supplier.currency,
  });
  const { warehouse } = useGetClosestWarehouse({
    facilityID: facility._id,
    warehouses: supplierProductDetail.warehouses,
    supplierProductDetail,
  });

  const { data: shippingRate, isLoading } = useShippingRate({
    facilityID: facility._id,
    warehouseID: warehouse?._id,
  });

  const shippingCost = supplierProductDetail.shippingIncluded
    ? 0
    : getProductShippingPrice({
        amount: amountToUse,
        exchangeRate: exchangeRate?.rate,
        shippingRate,
        supplierProductDetail,
      });

  let productAlreadyInCart = false;

  for (const productInCartByWarehouse of productsInCartByWarehouse) {
    if (
      productInCartByWarehouse.products.find(
        (productInCart) =>
          productInCart.supplierProductDetail.product._id ===
            supplierProductDetail.product._id &&
          productInCart.supplierProductDetail.supplier._id !==
            supplierProductDetail.supplier._id
      )
    )
      productAlreadyInCart = true;
  }

  if (
    !supplierProductDetail?.cost &&
    supplierProductDetail?.useTierPricing &&
    !supplierProductDetail?.tierPricing?.length
  )
    return;

  const addProductToShoppingCart = async (
    closestWarehouse,
    supplierProductDetail
  ) => {
    dispatch(setOrderFacility(facility._id));
    dispatch(setOrderFacilityAddress(facility.addressObject));

    dispatch(
      addToProductsInCartByWarehouse({
        closestWarehouse,
        quantity: amountToUse,
        supplierProductDetail,
      })
    );
    close();
  };

  if (
    !supplierProductDetail.cost &&
    !supplierProductDetail?.tierPricing?.length
  )
    return;

  const tierToUse = supplierProductDetail.tierPricing?.reduce(
    (prevTier, currentTier) => {
      if (
        currentTier.quantity <= amountToUse &&
        (!prevTier || currentTier.quantity > prevTier.quantity)
      ) {
        return currentTier;
      }
      return prevTier;
    },
    null
  );

  const productContainerSize =
    supplierProductDetail?.manufacturerProductDetail?.containerSize;
  const productContainerSizeUnit =
    supplierProductDetail?.manufacturerProductDetail?.containerSizeUnit;

  const total = getProductAndShippingTotal({
    amount: amountToUse,
    exchangeRate,
    shippingCost,
    supplierProductDetail,
    tierToUse: tierToUse?.cost ?? supplierProductDetail.cost,
  });

  return (
    <Grid
      item
      xs={12}
      md={size ?? 4}
      key={supplierProductDetail?.supplier?._id}
      display="flex"
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>
                {supplierProductDetail.supplier?.name}
                {supplierProductDetail?.manufacturerProductDetail?.manufacturer
                  ?.name !== supplierProductDetail.supplier?.name &&
                  supplierProductDetail?.manufacturerProductDetail?.manufacturer
                    ?.name && (
                    <>
                      {" "}
                      (
                      {
                        supplierProductDetail.manufacturerProductDetail
                          .manufacturer.name
                      }
                      )
                    </>
                  )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {warehouse && (
                <Typography>
                  {warehouse?.contact?.address.city +
                    ", " +
                    warehouse?.contact?.address.province}
                </Typography>
              )}
            </Grid>
            {supplierProductDetail?.manufacturerProductDetail?.lifeSpan && (
              <Grid item xs={12}>
                <Typography>
                  Estimated Life Span:{" "}
                  {supplierProductDetail.manufacturerProductDetail.lifeSpan}{" "}
                  years
                </Typography>
              </Grid>
            )}
            {supplierProductDetail.useTierPricing && tierToUse ? (
              <Grid item xs={12}>
                <Typography>
                  $
                  {calculateProductPrice(
                    tierToUse.cost,
                    exchangeRate?.rate,
                    supplierProductDetail.supplier._id
                  ).toFixed(2)}
                  /ea.
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography>
                  $
                  {calculateProductPrice(
                    supplierProductDetail.cost,
                    exchangeRate?.rate,
                    supplierProductDetail.supplier._id
                  ).toFixed(2)}
                  /ea.
                </Typography>
              </Grid>
            )}

            {supplierProductDetail.containerDeposit && (
              <Grid item xs={12}>
                <Typography>
                  Deposit: $
                  {calculateProductPrice(
                    supplierProductDetail.containerDeposit,
                    exchangeRate?.rate,
                    null
                  ).toFixed(2)}
                  /ea.
                </Typography>
              </Grid>
            )}
            {shippingCost > 0 && (
              <Grid item xs={12}>
                <Typography>
                  Shipping: ${(shippingCost / amountToUse).toFixed(2)}
                  /ea
                </Typography>
              </Grid>
            )}

            {(supplierProductDetail.containerDeposit || shippingCost > 0) && (
              <Grid item xs={12}>
                <Typography>
                  ${total.toFixed(2)}
                  /ea.
                </Typography>
              </Grid>
            )}

            {supplierProductDetail.shippingIncluded && (
              <Grid item xs={12}>
                <Typography color="blue">Shipping Included</Typography>
              </Grid>
            )}

            {productContainerSize && productContainerSizeUnit && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  {"$" +
                    (total / productContainerSize).toFixed(2) +
                    "/" +
                    productContainerSizeUnit}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>

        {checkFacilityUserLevelLogger(facilityUserLevel) && (
          <CardActions>
            {supplierProductDetail?.product?.obsoleteDate &&
            new Date(supplierProductDetail.product.obsoleteDate) <
              new Date() ? (
              <Button
                variant="contained"
                onClick={onClickShowEquivalentProducts}
                fullWidth
                color={color}
                disabled={!supplierProductDetail?.product?.equivalents?.length}
              >
                This product is obsolete. Please choose an equivalent product.
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={async () =>
                  await addProductToShoppingCart(
                    { ...warehouse, shippingRate },
                    supplierProductDetail
                  )
                }
                fullWidth
                color={color}
                disabled={
                  (orderFacility && facility._id !== orderFacility) ||
                  !!productAlreadyInCart ||
                  !warehouse ||
                  isLoading ||
                  isInitialLoading
                }
              >
                Add To Cart
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default ProductCard;
