import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import useQuickBooksAddFileAttachmentToBill from "../../customHooks/quickBooks/useQuickBooksAddFileAttachmentToBill";

const AttachFilesToQuickBooksBill = ({
  orderNumber,
  setAddFileAttachmentToSupplierBillOpen,
}) => {
  const [files, setFiles] = useState(null);

  const quickBooksAddFileAttachmentToBill =
    useQuickBooksAddFileAttachmentToBill();

  const close = () => {
    setFiles(null);
    setAddFileAttachmentToSupplierBillOpen(false);
  };

  const onSubmit = async () => {
    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }
    formData.append("orderNumber", orderNumber);

    await quickBooksAddFileAttachmentToBill.mutateAsync(formData);
    close();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Attachments</Typography>
      </Grid>
      <Grid item xs={12}>
        <input
          onChange={(e) => {
            setFiles(e.target.files);
          }}
          type="file"
          accept=".jpeg, .png, .jpg, .gif, .svg, .pdf"
          multiple
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Button variant="contained" onClick={close} fullWidth color="error">
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6} md={3}>
        <Button
          variant="contained"
          onClick={onSubmit}
          fullWidth
          color="success"
          disabled={!files?.length}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default AttachFilesToQuickBooksBill;
