import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOrderStatus } from "../../api/orderAPI";

const useUpdateOrderStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateOrderStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries(["orders", data._id]);
    },
  });
};

export default useUpdateOrderStatus;
