import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOrderAvailability } from "../../api/productOrderAPI";

const useUpdateOrderAvailability = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateOrderAvailability,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });
};

export default useUpdateOrderAvailability;
