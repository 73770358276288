import React, { useState } from "react";
import { CustomModal } from "../../sharedComponents";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
import useApproveServiceOrderRequest from "../../../customHooks/serviceOrder/useApproveServiceOrderRequest";
import useServiceOrderRequestQuoteUpdate from "../../../customHooks/serviceOrder/useServiceOrderRequestQuoteUpdate";
import { getServiceOrderQuotePDF } from "../../../api/serviceOrderAPI";
import { selectUser } from "../../../redux/reducers/userSlice";

const ServiceQuoteActionButtons = ({
  close,
  serviceOrderID,
  serviceQuoteID,
  serviceQuoteNumber,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const user = useSelector(selectUser);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [adjustModalOpen, setAdjustModalOpen] = useState(false);
  const [customerPONumber, setCustomerPONumber] = useState("");
  const [notes, setNotes] = useState("");

  const approveServiceOrderRequest = useApproveServiceOrderRequest();
  const requestQuoteUpdate = useServiceOrderRequestQuoteUpdate();

  const downloadQuote = async () => {
    try {
      const response = await getServiceOrderQuotePDF(
        serviceOrderID,
        serviceQuoteID
      );

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${serviceQuoteNumber}.pdf`);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const closeModal = () => {
    setApproveModalOpen(false);
    setAdjustModalOpen(false);
  };

  const approveServiceRequest = async () => {
    await approveServiceOrderRequest.mutateAsync({
      approvalUser: user._id,
      serviceOrderID,
      serviceQuoteID,
      customerPONumber,
      quoteAcceptedReason: notes,
    });

    closeModal();
    close();
  };

  const suggestAdjustment = async () => {
    await requestQuoteUpdate.mutateAsync({
      serviceOrderID,
      serviceQuoteID,
      reason: notes,
    });

    closeModal();
    close();
  };

  return (
    <Grid container spacing={2} item xs={12}>
      {(appUserLevel.includes("Super User") ||
        appUserLevel.includes("Facility")) && (
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadQuote()}
            fullWidth
          >
            Download Quote
          </Button>
        </Grid>
      )}

      {(appUserLevel.includes("Super User") ||
        appUserLevel.includes("Facility")) && (
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setApproveModalOpen(true)}
            fullWidth
          >
            Approve
          </Button>
        </Grid>
      )}
      {(appUserLevel.includes("Super User") ||
        appUserLevel.includes("Facility")) && (
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setAdjustModalOpen(true)}
            fullWidth
          >
            Request Adjustment
          </Button>
        </Grid>
      )}

      <CustomModal
        open={approveModalOpen}
        close={closeModal}
        title="Approve Service Request"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              By clicking "Approve" you are confirming that the service quote is
              accurate and you are ready to proceed with the service.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Customer PO #"
              variant="outlined"
              fullWidth
              value={customerPONumber}
              onChange={(e) => setCustomerPONumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="(Optional) Reason For Approval"
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                approveServiceRequest();
              }}
              fullWidth
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={adjustModalOpen}
        close={closeModal}
        title="Service Adjustment"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please describe the adjustments you wish the supplier to make for
              this quote
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Adjustments"
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                suggestAdjustment();
              }}
              fullWidth
            >
              Request Adjustment
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export default ServiceQuoteActionButtons;
