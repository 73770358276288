import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import useUpdateOrderAvailability from "../../customHooks/productOrder/useUpdateOrderAvailability";

const UpdateOrderAvailableDate = ({ orderID, pickUpAvailableDate }) => {
  const updateOrderAvailability = useUpdateOrderAvailability();

  const [availableDate, setAvailableDate] = useState(
    moment(pickUpAvailableDate)
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const onOpen = () => {
    setModalOpen(true);
  };

  const onClose = (event, reason) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
  };

  const onSubmit = async () => {
    setSaving(true);
    await updateOrderAvailability.mutateAsync({
      availableDate: availableDate.toISOString(),
      orderID: orderID,
    });

    onClose();
    setSaving(false);
  };

  return (
    <>
      <CustomModal
        open={modalOpen}
        close={onClose}
        title="Update Available Date"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              label="Available Date"
              value={availableDate}
              onChange={setAvailableDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={onSubmit}
              fullWidth
              disabled={saving}
            >
              {saving ? "Saving" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      <Grid item xs={12}>
        <Button variant="contained" fullWidth onClick={onOpen}>
          Update Available Date
        </Button>
      </Grid>
    </>
  );
};

export default UpdateOrderAvailableDate;
